.searchResultItemWrapper {

}

.listItemWrapper{
  border-radius: 12px;
  background: rgb(255 255 255 / 50%);
  display: flex;
  padding: 8px;
  margin-bottom: 16px;
}

.listItemImage{

  img{
    border-radius: 9px;
  }
}

.noListItemImagePlaceholder{
  width: 115px;
  height: 65px;
  background-color: #999;
  border-radius: 9px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.listItemText{
  display: flex;
  width: 100%;
  padding-left: 8px;
  @media( min-width:425px ){
    padding-left: 16px;
  }

  mark{
    background-color: transparent;
    text-decoration: underline;
  }

  :global{

    .ais-Highlight-highlighted{
      font-weight: bold;
      color: var(--primary-color);
    }

    .ant-typography{
      font-size: 16px;
      margin: 0;
      color: var(--grey-9);
      flex-grow: 1;
      display: block;
      width: 100%;
      line-height: 24px;
    }
  }
}


.listItemStatistics{
  width: 70px;
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  color: var(--grey-8);
  line-height: 24px;
}