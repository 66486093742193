.coverSearchboxWrapper {
  padding: 0 6px;
  @media( min-width:425px ){
    padding: 0 16px;
  }
}

.searchBox{
  position: relative;

  :global{

    .anticon-search{
      position: absolute;
      top: 13px;
      left: 12px;
      z-index: 5;
      font-size: 18px;
      color: var(--grey-9);
    }
  }
}


.coverSearchbox {
  max-width: 800px;
  padding: 16px 8px;
  margin: 0 auto 42px;
  position: relative;
  background: linear-gradient(125deg, rgb(255 255 255 / 79%) 0.63%, rgb(255 255 255 / 65%) 17.6%, rgb(255 255 255 / 5%) 93.17%, rgb(255 255 255 / 0%) 96.18%);
  border: 1px solid #efeff1;
  border-radius: 16px;
  min-height: 420px;

  @media( min-width:425px ){
    padding: 24px;
  }

  :global{
    
    .ais-SearchBox{
      position: relative;
      z-index: 4;


      &-reset, &-loadingIndicator{
        display: none;
      }

      &-input{
        display: block;
        width: 100%;
        box-shadow: 0 25px 50px 0 rgb(8 24 35 / 25%);
        border-radius: 27px;
        border: 1px solid var(--grey-grey-5, #d0d1d6);
        background: linear-gradient(90deg, rgb(255 255 255 / 80%) 1.2%, rgb(255 255 255 / 73%) 60.24%, rgb(255 255 255 / 68%) 91.19%, rgb(255 255 255 / 0%) 139.42%);
        font-size: 16px;
        line-height: 24px;
        padding: 8px 32px;
      }

      &-submit{
        display: none;
      }
    }

    .ais-Hits{
      min-height: 300px;

      
    }
  }

  ::-webkit-scrollbar {
    width: 4px;
  }

  /* Track */

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */

  ::-webkit-scrollbar-thumb {
    background: #fff;
    border-radius: 2px;
  }

  /* Handle on hover */

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

.hitsListWrapper{
  max-height: 300px;
  overflow-y: auto;
  list-style: none;
  padding: 0;
  margin: 8px 0 0;
}

.searchStatistics{
  text-align: right;
  color: #fff;
  font-size: 14px;
  font-weight: normal;
}

.hitsPanelTitle{
  font-size: 14px;
  color: var(--grey-8);
  margin-bottom: 0;
  margin-top: 0;
  font-weight: lighter;
}